<script lang="ts" setup>
const { getShopData } = useShopData();

const shopCover = computed(() => getShopData.value?.cover || '');
</script>

<template>
  <div class="shop-header">
    <div
      v-if="shopCover"
      class="shop-header-cover"
    >
      <div
        class="shop-header-cover-background"
        :style="{ backgroundImage: `url(${shopCover})` }"
      />
      <div class="shop-header-cover-background-fitter" />
      <nuxt-img
        aspect-ratio="4/1"
        class="relative m-auto aspect-[4/1] w-full max-w-[1050px] overflow-hidden rounded-lg object-cover"
        provider="cloudflare"
        :alt="getShopData?.shopName || ''"
        :src="shopCover"
        :width="1050"
      />
    </div>
    <ShopInformation class="relative -top-3 md:top-0" />
  </div>
</template>

<style lang="scss" scoped>
.shop-header {
  @include max-container-width;

  position: relative;
  overflow: hidden;
  background-color: var(--white);

  &-cover {
    position: relative;
    padding: 5px 5px 0;

    &-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      filter: blur(100px);
      opacity: .5;
    }
  }
}

@include bp-desktop {
  .shop-header {
    &-cover {
      padding: 10px 20px 0;

      &-background {
        &-fitter {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 20px;
          margin-left: -20px;
          background-color: var(--white);
        }
      }
    }
  }
}
</style>
